export default () => {

  // used to redirect from /lawenforcement to /law-enforcement-guidelines (for Prismic preview)
  // if this page is removed and someone is trying to reach aurora.tech/lawenforcement, she will be redirected to "Page not Found"

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    window.location.href = '/law-enforcement-guidelines'
  }
  return false;
};
